<template>
  <c-box
      display="flex"
      :pb="['47px', 0]"
      :minHeight="['100vh']"
      backgroundImage="linear-gradient(180deg, #008C817f 50%, #F4CC467f 100%), url(/images/bg-login.png)"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      justifyContent="center"
      alignItems="center"
  >
    <c-box w="100%" maxWidth="472px" textAlign="center">
      <c-image
          :src="require('@/assets/logo-white.png')"
          alt="Logo"
          mx="auto"
          marginBottom="50px"
      />
      <c-text
          color="white"
          fontSize="24px"
          fontWeight="500"
          marginBottom="50px"
          fontFamily="Montserrat"
      >
        Perbaiki Dietmu Jangan Tunda Lagi
      </c-text>
      <c-form-control marginBottom="30px" px="20px">
        <c-button
            backgroundColor="white"
            px="26px"
            fontSize="18px"
            fontWeight="500"
            py="26px"
            borderRadius="58px"
            width="100%"
            color="brand.900"
            as="router-link"
            :to="{ name: 'auth.login' }"
        >
          Login
        </c-button>
        <!-- <template v-if="errors.length > 0">
          <c-form-helper-text v-for="error in errors" :key="error" color="red">
            {{ error }}
          </c-form-helper-text>
        </template> -->
      </c-form-control>
      <c-form-control marginBottom="30px" px="20px">
        <c-button
            backgroundColor="white"
            px="26px"
            fontSize="18px"
            fontWeight="500"
            py="26px"
            borderRadius="58px"
            width="100%"
            color="brand.900"
            as="router-link"
            :to="{ name: 'auth.register' }"
        >
          Sign Up
        </c-button>
        <!-- <template v-if="errors.length > 0">
          <c-form-helper-text v-for="error in errors" :key="error" color="red">
            {{ error }}
          </c-form-helper-text>
        </template> -->
      </c-form-control>

      <c-flex
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
          pos="relative"
          px="20px"
      >
        <c-box display="block" width="400%" height="1px" bgColor="#ffffff"/>
        <c-box
            as="span"
            pos="relative"
            zIndex="1"
            color="#ffffff"
            display="inline-block"
            py="6px"
            px="12px"
            fontSize="14px"
        >
          atau
        </c-box>
        <c-box display="block" width="400%" height="1px" bgColor="#ffffff"/>
      </c-flex>
      <c-form-control
          px="20px"
          v-chakra="{
          button: {
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
        }"
      >
        <c-button
            border="1px solid #ffffff"
            borderRadius="100px"
            bg="transparent"
            w="100%"
            color="#ffffff"
            py="25px"
            fontSize="18px"
            fontWeight="500"
            fontFamily="Roboto"
            @click.prevent.stop="googleLogin()"
        >
          <c-image
              :src="require('@/assets/icon-google-white.png')"
              alt="google"
              mr="10px"
          />
          Masuk dengan Google
        </c-button>
      </c-form-control>
    </c-box>
    <Portal to="main" v-if="errors.length > 0">
      <c-box pos="fixed" bottom="20px" right="20px" z-index="1001">
        <c-alert status="error">
          <c-alert-icon/>
          <c-alert-description>
            <template v-for="error in errors">
              <c-text :key="error">{{ error }}</c-text>
            </template>
          </c-alert-description>
        </c-alert>
      </c-box>
    </Portal>
    <Portal to="main" v-if="isLoggingIn">
      <c-box pos="fixed" bottom="20px" right="20px" z-index="1001">
        <c-alert status="info">
          <c-alert-description> Memuat data...</c-alert-description>
        </c-alert>
      </c-box>
    </Portal>
  </c-box>
</template>

<script>
import Logo from "@/assets/logo-white.svg";
import {mapActions, mapState} from "vuex";
import equal from "fast-deep-equal";

export default {
  name: "EmptyLoginPage",
  components: {},
  data() {
    return {
      Logo,
      showPassword: false,
      email: "",
      password: "",
      // ---
      errors: [],
      isLoggingIn: false,
    };
  },
  computed: {
    ...mapState({
      token: (s) => s.auth.token,
      user: (s) => s.auth.user,
    }),
  },
  watch: {
    token: {
      immediate: true,
      handler(token) {
        if (token != null) {
          if (this.$route.query.next != null) {
            this.$router.replace(this.$route.query.next);
          } else {
            if (this.user.role === "super_admin") {
              this.$router.replace("/management");
            } else {
              this.$router.replace("/");
            }
          }
        }
      },
    },
    errors(val, old) {
      if (equal(val, old)) return;
      if (val?.length <= 0) return;

      this.$nextTick(() => {
        let id = setTimeout(() => {
          if (id) clearTimeout(id);

          this.errors = [];
        }, 3000);
      });
    },
  },
  methods: {
    ...mapActions({
      signin: "auth/signin",
    }),
    login() {
      this.errors = [];
      this.signin({email: this.email, password: this.password}).catch(
          (err) => {
            this.errors = [...err];
          }
      );
    },
    googleLogin() {
      this.isLoggingIn = true;
      this.win = window.open("about:blank", "_blank");
      this.axios
          .post(`/v1/auth/generate-google-url`)
          .then((r) => r.data.data)
          .then((url) => {
            this.win.location.href = url;
          });
    },
    onWindowMessage($event) {
      console.log("$event", $event);
      let isSameOrigin =
          window.location.origin === $event.target.location.origin &&
          window === $event.target;
      let isContainsGoogleCode = $event.data.code != null;

      if (isSameOrigin && isContainsGoogleCode) {
        this.win.close();
        this.axios
            .post(`/v1/auth/login-oauth`, $event.data)
            .then((r) => r.data.data)
            .then((r) => {
              let {token, refreshToken, ...user} = r;
              this.$store.commit("auth/setUser", user);
              this.$store.commit("auth/setToken", token);
              this.$store.commit("auth/setRefreshToken", refreshToken);
            })
            .catch((err) => {
              console.error("error while trying to signin with google code", err);

              let msg = err.response.data?.message
              if (msg.includes("user doesn't exists")) {
                this.$router.replace({ name: 'auth.register' })
              } else {
                this.$errorToastFromCatch(err)
                // this.errors = [msg]
              }
            })
            .finally(() => {
              this.isLoggingIn = false;
            });
      }
    },
  },
  mounted() {
    window.addEventListener("message", this.onWindowMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onWindowMessage);
  },
};
</script>

<style></style>
